import polyglotI18nProvider from "ra-i18n-polyglot";
import React, { lazy } from "react";
import { Admin, CustomRoutes } from "react-admin";
import ReactDOM from "react-dom/client";
import { Route } from "react-router-dom";

import "../index.scss";

import portugueseMessages from "../i18n/pt";
import theme from "../theme";

const AnonymousPaymentShow = lazy(() => import("../container/anonymous/payment/show"));

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

const i18nProvider = polyglotI18nProvider(() => portugueseMessages);

function App()
{
  return (
    <Admin theme={theme} i18nProvider={i18nProvider} title="Pagamento">
      <CustomRoutes noLayout>
        <Route path="/link-pagamento/:hash" Component={AnonymousPaymentShow} />
      </CustomRoutes>
    </Admin>
  );
}
